import { useRef } from 'react'
import { FaFileDownload, FaEnvelope, FaPhone } from 'react-icons/fa'
import './contact.css'
import CV from '../../assets/Richard Erdursun CV 2025.pdf'

const Contact = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here
  };

  return (
    <section id="contact">
      <div className="waves">
        <div className="wave-shape shape1"></div>
        <div className="wave-shape shape2"></div>
        <div className="wave-shape shape3"></div>
      </div>
      
      <div className="container contact__container">
        <h2>Let's Work Together</h2>
        <p className="intro">
          Have a project in mind or just want to chat? I'd love to hear from you! Whether you're looking for a UX/UI designer, product developer, or AI integration expert, let's create something amazing together.
        </p>

        {/* Contact Form with glass effect - moved above */}
        <form ref={form} className="contact-form glass-effect" onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" name="name" placeholder="Your Name" required />
          </div>
          <div className="form-group">
            <input type="email" name="email" placeholder="Your Email" required />
          </div>
          <div className="form-group">
            <textarea name="message" placeholder="Your Message" required></textarea>
          </div>
          <button type="submit" className="btn primary">Send Message</button>
        </form>

        <div className="contact-options">
          {/* CV Download */}
          <div className="card contact-card glass-effect">
            <div className="contact-icon-wrapper">
              <FaFileDownload className="contact-icon" />
            </div>
            <h3>Download My CV</h3>
            <p>Get a detailed overview of my skills and experience.</p>
            <a href={CV} className="btn primary" download>
              Download CV
            </a>
          </div>

          {/* Email */}
          <div className="card contact-card glass-effect">
            <div className="contact-icon-wrapper">
              <FaEnvelope className="contact-icon" />
            </div>
            <h3>Email Me</h3>
            <p>Reach out directly at</p>
            <a href="mailto:mr@richarderdursun.com" className="contact-detail">mr@richarderdursun.com</a>
          </div>

          {/* Phone */}
          <div className="card contact-card glass-effect">
            <div className="contact-icon-wrapper">
              <FaPhone className="contact-icon" />
            </div>
            <h3>Call Me</h3>
            <p>Let's talk!</p>
            <a href="tel:+447576270727" className="contact-detail">+44 7576 270727</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact