import {AiFillInstagram} from 'react-icons/ai'
import {FaXTwitter} from 'react-icons/fa6'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {BsWhatsapp} from 'react-icons/bs'

const data = [
    {
        id: 1,
        link: 'https://www.linkedin.com/in/richard-erdursun/',
        icon: <AiOutlineLinkedin/>
    },
    {
        id: 2,
        link: 'https://twitter.com/oddwalkstudios',
        icon: <FaXTwitter/>
    },
    {
        id: 3,
        link: 'https://www.instagram.com/slimed_videodrome/',
        icon: <AiFillInstagram/>
    },
    {
        id: 4,
        link: 'https://wa.me/447576270727',
        icon: <BsWhatsapp/>
    }
]

export default data

