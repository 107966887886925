import './about.css'
import AboutImg from '../../assets/about.png'
import CV from '../../assets/Richard Erdursun CV 2025.pdf'
import {HiDownload} from 'react-icons/hi'
import Card from '../../components/Card'
import data from './data'

const About = () => {
  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImg} alt="About Portrait" />
          </div>
        </div>
        <div className="about__right">
          <h2>
            Nice to meet you! <span className="wave">👋</span>
          </h2>
          <p className="tagline">
            I am a <strong>UX/UI Designer</strong> & <strong>Product Developer</strong> crafting AI-driven solutions for global enterprises and startups.
          </p>
          <p className="bio">
            With <strong>10+ years</strong> of design experience across 50+ projects, I specialise in transforming complex business challenges into intuitive digital experiences that deliver measurable impact:
          </p>
          <ul className="value-list">
            <li>🚀 +35% Increase in User Engagement</li>
            <li>💼 +28% Boost in Conversion Rates</li>
            <li>💡 -25% Reduction in Development Costs</li>
          </ul>

          <div className="about__cards">
            {data.map(item => (
              <Card key={item.id} className="about__card">
                <span className='about__card-icon'>{item.icon}</span>
                <h5>{item.title}</h5>
                <small>{item.desc}</small>
              </Card>
            ))}
          </div>

          <a href={CV} download className='btn primary'>
            <span>Download CV</span>
            <HiDownload 
              size={20} 
              style={{ 
                display: 'inline-block', 
                marginLeft: 'auto',
                position: 'relative',
                top: '1px'
              }} 
            />
          </a>
        </div>
      </div>
    </section>
  )
}

export default About