import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './faqs.css'

const FAQs = () => {
  useEffect(() => {
    AOS.init({duration: 1500})
  }, [])

  const processSteps = [
    {
      number: "1",
      title: "Discovery & Strategy",
      description: "I begin by understanding your business goals, target audience, and market landscape through in-depth research and collaboration.",
      items: [
        "Stakeholder Workshops to align on objectives",
        "User Interviews to uncover pain points",
        "Competitive Analysis to identify opportunities"
      ],
      caseStudy: "See how this drove 40% faster onboarding →"
    },
    {
      number: "2",
      title: "Design & Prototyping",
      description: "Using tools like Figma and Adobe XD, I create wireframes, prototypes, and high-fidelity designs that align with user needs and business goals.",
      items: [
        "Wireframing for structure and flow",
        "Interactive Prototypes for user testing",
        "Design Systems for consistency"
      ],
      caseStudy: "Explore the AI-driven redesign →"
    },
    {
      number: "3",
      title: "Testing & Iteration",
      description: "I conduct usability testing and gather feedback to refine designs, ensuring they are intuitive, accessible, and effective.",
      items: [
        "Usability Testing with real users",
        "A/B Testing to validate solutions",
        "Analytics Review to measure performance"
      ],
      caseStudy: "See how testing improved engagement by 30% →"
    },
    {
      number: "4",
      title: "Delivery & Optimisation",
      description: "I collaborate with developers to ensure seamless implementation and continuously optimise designs based on user feedback and performance metrics.",
      items: [
        "Handoff to Developers with detailed specs",
        "Post-Launch Analysis to track success",
        "Ongoing Support for continuous improvement"
      ],
      caseStudy: "Learn how optimisation boosted conversions by 25% →"
    }
  ];

  const handleClick = (e) => {
    e.preventDefault();
    const portfolio = document.querySelector('#portfolio');
    const navHeight = document.querySelector('nav').offsetHeight;
    const position = portfolio.offsetTop - navHeight;
    
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  };

  return (
    <section id="process">
      <h1>From Concept to Impact: My Design Process</h1>
      <p>A structured, user-centered approach to solving complex problems and delivering measurable results.</p>
      <div className="container">
        <div className="process__container">
          {processSteps.map((step, index) => (
            <div 
              className="process-card" 
              key={step.number}
              data-aos="zoom-in-up"
              data-aos-delay={index * 100}
            >
              <div className="step-header">
                <span className="step-number">{step.number}</span>
                <h3>{step.title}</h3>
              </div>
              <p>{step.description}</p>
              <ul className="process-items">
                {step.items.map((item, i) => (
                  <li 
                    key={i}
                    className="process-item"
                    style={{ animationDelay: `${i * 0.1}s` }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              {step.caseStudy && (
                <a 
                  href="#portfolio" 
                  onClick={handleClick}
                  className="case-study-link"
                  data-aos="fade-left"
                  data-aos-delay={index * 100}
                >
                  {step.caseStudy} →
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQs