import { useEffect } from 'react'
import HeaderImage from '../../assets/header.jpg'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'
import { motion } from 'framer-motion'
import { FaArrowRight } from 'react-icons/fa'
import { BsArrowRight } from 'react-icons/bs'
import { HiArrowRight } from 'react-icons/hi'
import ParticleBackground from './ParticleBackground'

const Header = () => {
  console.log('Rendering Header'); // Debug log

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  const handleClick = (e) => {
    e.preventDefault();
    const portfolio = document.querySelector('#portfolio');
    const navHeight = document.querySelector('nav').offsetHeight;
    const position = portfolio.offsetTop - navHeight;
    
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  };

  const handleSocialClick = (url, e) => {
    e.preventDefault();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <header id="header">
      <ParticleBackground />
      <div className="container header__container">
        <motion.div 
          className="header__profile"
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img src={HeaderImage} alt="Profile Picture" />
        </motion.div>

        <motion.div className="header__content">
          <h3>Hello, I'm</h3>
          <h1>Richard Erdursun</h1>
          <h2>
            <span className="gradient-design">UX/UI Designer</span>
            {' '}&{' '}
            <span className="gradient-dev">Product Developer</span>
          </h2>
          <p>
            Crafting intuitive digital experiences that delight users and drive business success.
          </p>
        </motion.div>

        <motion.div 
          className="header__cta"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <a href="#contact" className='btn primary'>
            <span>Let's Talk</span>
            <FaArrowRight 
              size={20} 
              style={{ 
                display: 'inline-block', 
                marginLeft: 'auto',
                position: 'relative',
                top: '1px'
              }} 
            />
          </a>
          <a href="#portfolio" onClick={handleClick} className='btn light'>
            <span>View My Work</span>
            <FaArrowRight 
              size={20} 
              style={{ 
                display: 'inline-block', 
                marginLeft: 'auto',
                position: 'relative',
                top: '1px'
              }} 
            />
          </a>
        </motion.div>

        <motion.div 
          className="header__socials"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          {data.map(item => (
            <motion.a 
              key={item.id} 
              href={item.link} 
              onClick={(e) => handleSocialClick(item.link, e)}
              target="_blank" 
              rel="noopener noreferrer"
              whileHover={{ scale: 1.1, y: -5 }}
              whileTap={{ scale: 0.95 }}
            >
              {item.icon}
            </motion.a>
          ))}
        </motion.div>
      </div>
    </header>
  )
}

export default Header