import {createContext, useContext, useReducer, useEffect} from "react"
import themeReducer from "./themeReducer";

// Add this enum/constant for action types
export const THEME_ACTION_TYPES = {
    CHANGE_THEME: 'CHANGE_THEME',
    CHANGE_BACKGROUND: 'CHANGE_BACKGROUND'
}

export const ThemeContext = createContext();

// get theme settings from local storage, or use default theme
const getInitialState = () => {
    const savedTheme = localStorage.getItem('themeSettings');
    return savedTheme ? JSON.parse(savedTheme) : {
        primary: 'color-2',  // Default green theme
        background: 'bg-2'   // Default dark mode
    };
}

export const ThemeProvider = ({children}) => {
    const [themeState, dispatch] = useReducer(themeReducer, getInitialState());

    const themeHandler = (buttonId) => {
        dispatch({type: THEME_ACTION_TYPES.CHANGE_THEME, payload: buttonId})
    }

    const backgroundHandler = (buttonId) => {
        dispatch({type: THEME_ACTION_TYPES.CHANGE_BACKGROUND, payload: buttonId})
    }

    // save theme settings to local storage
    useEffect(() => {
        localStorage.setItem('themeSettings', JSON.stringify(themeState))
    }, [themeState.primary, themeState.background])

    return <ThemeContext.Provider value={{
        themeState,
        themeHandler,
        backgroundHandler
    }}>{children}</ThemeContext.Provider>
}

// custom hook to use our theme context wherever we want in our project
export const useThemeContext = () => {
    return useContext(ThemeContext);
}