import './testimonials.css'
import { motion } from 'framer-motion'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR5 from '../../assets/avatar5.jpg'

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      quote: "Richard's designs transformed our app's user experience. His attention to detail and user-centered approach led to a 40% increase in user engagement.",
      avatar: AVTR1,
      name: "Sarah Johnson",
      role: "Product Director, TechCorp",
      company: "TechCorp"
    },
    {
      id: 2,
      quote: "Working with Richard was exceptional. His ability to blend UX principles with modern AI solutions helped us achieve a 25% improvement in conversion rates.",
      avatar: AVTR2,
      name: "Michael Chen",
      role: "CEO",
      company: "InnovateLab"
    },
    {
      id: 3,
      quote: "Richard's expertise in both design and development made our collaboration seamless. His work on our design system reduced development time by 35%.",
      avatar: AVTR5,
      name: "Emma Rodriguez",
      role: "Head of Design",
      company: "DesignFlow"
    }
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  }

  return (
    <section id="testimonials">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <h2>What People Say</h2>
        <p className="intro">
          Here's what clients and colleagues have to say about working with me.
        </p>
      </motion.div>

      <motion.div 
        className="testimonials-grid"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {testimonials.map(({ id, quote, avatar, name, role, company }) => (
          <motion.div 
            key={id} 
            className="testimonial-card"
            variants={cardVariants}
          >
            <div className="testimonial-content">
              <div className="quote-icon">❝</div>
              <blockquote>{quote}</blockquote>
              <div className="testimonial-author">
                <img src={avatar} alt={name} className="avatar" />
                <div className="author-info">
                  <p className="name">{name}</p>
                  <p className="role">{role} • {company}</p>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  )
}

export default Testimonials