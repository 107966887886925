import { useEffect } from 'react'
import './CustomCursor.css'  // Create this new file for cursor styles

const CustomCursor = () => {
  useEffect(() => {
    // Only run on desktop
    if (window.innerWidth < 1024) return;

    // Add this debug line
    console.log('Dark mode:', document.documentElement.classList.contains('dark'));

    // Create both cursor elements
    const glow = document.createElement('div');
    const dot = document.createElement('div');
    glow.className = 'cursor-glow';
    dot.className = 'cursor-dot';
    
    // Add data attribute for easier debugging
    dot.setAttribute('data-cursor', 'dot');
    
    // Check dark mode and add a class directly
    if (document.documentElement.classList.contains('dark')) {
      dot.classList.add('dark-mode-dot');
      console.log('Dark mode is active');
    }

    document.body.appendChild(glow);
    document.body.appendChild(dot);

    let mouseX = 0;
    let mouseY = 0;
    let glowX = 0;
    let glowY = 0;

    const animate = () => {
      const distX = mouseX - glowX;
      const distY = mouseY - glowY;
      
      glowX += distX * 0.25;
      glowY += distY * 0.25;

      glow.style.left = glowX + 'px';
      glow.style.top = glowY + 'px';
      
      dot.style.left = mouseX + 'px';
      dot.style.top = mouseY + 'px';

      requestAnimationFrame(animate);
    };

    const handleMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    document.addEventListener('mousemove', handleMouseMove);
    animate();

    // Snappier hover transitions
    const handleMouseEnter = () => {
      glow.classList.add('cursor-hover');
      dot.classList.add('cursor-hover');
    };

    const handleMouseLeave = () => {
      glow.classList.remove('cursor-hover');
      dot.classList.remove('cursor-hover');
    };

    const interactiveElements = document.querySelectorAll('a, button, input, textarea');
    interactiveElements.forEach(el => {
      el.addEventListener('mouseenter', handleMouseEnter);
      el.addEventListener('mouseleave', handleMouseLeave);
    });

    // Cleanup
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      interactiveElements.forEach(el => {
        el.removeEventListener('mouseenter', handleMouseEnter);
        el.removeEventListener('mouseleave', handleMouseLeave);
      });
      glow?.remove();
      dot?.remove();
    };
  }, []);

  return null;
}

export default CustomCursor