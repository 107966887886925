const Nav = ({className, item}) => {
  const handleClick = (e) => {
    e.preventDefault();
    
    // Get target element
    const targetId = item.link.slice(1); // Remove the '#' from the link
    const element = document.getElementById(targetId);
    
    // Debug logs
    console.log('Nav Click:', {
      targetId,
      element,
      link: item.link,
      className,
      isActive: className.includes('active')
    });

    if (!element) {
      console.error('Target element not found:', targetId);
      return;
    }

    // Get the navbar height
    const navHeight = document.querySelector('nav').offsetHeight;
    
    // Calculate position
    const position = element.offsetTop - navHeight;
    
    // Perform smooth scroll
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  };

  return (
    <a 
      href={item.link} 
      onClick={handleClick} 
      className={className}
      data-link={item.link} // Add this for debugging
    >
      {item.icon}
    </a>
  );
};

export default Nav;