import { useThemeContext } from './context/theme-context'
import { useModalContext } from './context/modal-context'
import Navbar from './sections/navbar/Navbar'
import Header from './sections/header/Header'
import About from './sections/about/About'
import Portfolio from './sections/portfolio/Portfolio'
import Testimonials from './sections/testimonials/Testimonials'
import FAQs from './sections/faqs/FAQs'
import Contact from './sections/contact/Contact'
import Footer from './sections/footer/Footer'
import Modal from './components/Modal'
import CustomCursor from './components/CustomCursor'
import Analytics from './components/Analytics'
import Theme from './theme/Theme'
import Skills from './sections/skills/Skills'
import Experience from './sections/experience/Experience'

const App = () => {
  const {themeState} = useThemeContext()
  const {showModal} = useModalContext()

  return (
    <>
      <CustomCursor />
      <main className={`${themeState.primary} ${themeState.background}`}>
        <Analytics />
        <Navbar/>
        <Header/>
        <About/>
        <Experience/>
        <Skills/>
        <Portfolio/>
        <Testimonials/>
        <FAQs/>
        <Contact/>
        <Footer/>
        {showModal && <Modal/>}
        <Theme />
      </main>
    </>
  )
}

export default App