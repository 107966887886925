import {AiOutlineHome} from 'react-icons/ai'
import {CgProfile} from 'react-icons/cg'
import {BiBriefcase} from 'react-icons/bi'
import {RiHandHeartLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'

const data = [
    {id: 1, link: '#header', icon: <AiOutlineHome/>},
    {id: 2, link: '#about', icon: <CgProfile/>},
    {id: 3, link: '#portfolio', icon: <BiBriefcase/>},
    {id: 4, link: '#process', icon: <RiHandHeartLine/>},
    {id: 5, link: '#contact', icon: <BiMessageSquareDetail/>}
]

export default data