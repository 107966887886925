import React from 'react';

const ParticleBackground = () => {
    return (
        <div className="particles-container">
            {/* Regular particles */}
            {[...Array(50)].map((_, index) => (
                <div key={`particle-${index}`} className={`particle particle-${index + 1}`} />
            ))}
            
            {/* Glowing particles */}
            {[...Array(15)].map((_, index) => (
                <div key={`glow-${index}`} className={`particle-glow glow-${index + 1}`} />
            ))}
        </div>
    );
};

export default ParticleBackground;