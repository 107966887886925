import { useThemeContext } from './../context/theme-context';

const BackgroundColor = ({className}) => {
  const {backgroundHandler} = useThemeContext()

  return (
    <button className={className} onClick={() => backgroundHandler(className)}></button>
  )
}

export default BackgroundColor