import Logo from '../../assets/logo.jpg'
import data from './data'
import {IoIosColorPalette} from 'react-icons/io'
import { useModalContext } from '../../context/modal-context'
import './navbar.css'
import { AiOutlineHome } from 'react-icons/ai'
import { BiUser, BiBriefcase, BiMessageSquareDetail } from 'react-icons/bi'
import { RiHandHeartLine } from 'react-icons/ri'
import { MdHandshake } from 'react-icons/md'

const Navbar = () => {
  const {showModalHandler} = useModalContext();

  const handleClick = (e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    const element = document.querySelector(href);
    const navHeight = document.querySelector('nav').offsetHeight;
    const position = element.offsetTop - navHeight;
    
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  };

  return (
    <nav>
      <div className="container nav__container">
        <a href="#header" className='nav__logo'>
          <img src={Logo} alt="Logo" />
        </a>
        <ul className='nav__menu'>
          {
            data.map(item => (
              <li key={item.id}>
                <a href={item.link} onClick={handleClick}>
                  {item.title}
                </a>
              </li>
            ))
          }
        </ul>
        <button id='theme__icon' onClick={showModalHandler}>
          <IoIosColorPalette/>
        </button>
      </div>

      {/* Floating Navigation */}
      <div className="floating__nav">
        <a href="#header" onClick={handleClick}>
          <AiOutlineHome />
        </a>
        <a href="#about" onClick={handleClick}>
          <BiUser />
        </a>
        <a href="#portfolio" onClick={handleClick}>
          <BiBriefcase />
        </a>
        <a href="#process" onClick={handleClick}>
          <RiHandHeartLine />
        </a>
        <a href="#contact" onClick={handleClick}>
          <BiMessageSquareDetail />
        </a>
      </div>
    </nav>
  )
}

export default Navbar