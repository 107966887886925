import './skills.css'
import { FaSearch, FaPalette, FaRocket, FaRobot, FaCode, FaUsers } from 'react-icons/fa'
import { motion } from 'framer-motion'

const Skills = () => {
  const skills = [
    {
      id: 1,
      icon: <FaSearch />,
      title: "UX Research",
      tools: ["User Interviews", "Usability Testing", "Journey Mapping", "Analytics (Hotjar/GA)"]
    },
    {
      id: 2,
      icon: <FaPalette />,
      title: "UI/Visual Design",
      tools: ["Figma/Adobe XD", "Design Systems", "Micro-Interactions", "Accessibility (WCAG)"]
    },
    {
      id: 3,
      icon: <FaRocket />,
      title: "Product Development",
      tools: ["Agile/Scrum", "Shopify/WordPress", "API Integration", "Conversion Optimization"]
    },
    {
      id: 4,
      icon: <FaRobot />,
      title: "AI Integration",
      tools: ["AI Prototyping", "MidJourney/DALL-E", "Workflow Automation", "Predictive Analytics"]
    },
    {
      id: 5,
      icon: <FaCode />,
      title: "Technical Stack",
      tools: ["HTML5/CSS3", "JavaScript/React", "GitHub/Cursor", "Responsive Design"]
    },
    {
      id: 6,
      icon: <FaUsers />,
      title: "Team Leadership",
      tools: ["Jira/Miro", "Stakeholder Alignment", "Design Sprints", "Client Workshops"]
    }
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  }

  const iconVariants = {
    hidden: { scale: 0 },
    visible: { 
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        delay: 0.2
      }
    }
  }

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  }

  const itemVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: { 
      x: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  }

  return (
    <section id="skills">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        My Skills
      </motion.h2>
      <motion.div 
        className="container"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="skills-grid">
          {skills.map(({ id, icon, title, tools }) => (
            <motion.div
              key={id}
              className="skill-card"
              variants={cardVariants}
              whileHover={{ 
                scale: 1.03,
                boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
                transition: { duration: 0.3 }
              }}
              whileTap={{ scale: 0.98 }}
            >
              <motion.div 
                className="skill-icon"
                variants={iconVariants}
              >
                {icon}
              </motion.div>
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {title}
              </motion.h3>
              <motion.ul
                variants={listVariants}
              >
                {tools.map((tool, index) => (
                  <motion.li 
                    key={index}
                    variants={itemVariants}
                    whileHover={{ 
                      x: 10,
                      color: 'var(--color-primary)',
                      transition: { duration: 0.2 }
                    }}
                  >
                    {tool}
                  </motion.li>
                ))}
              </motion.ul>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  )
}

export default Skills 