import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
import { ModalProvider } from './context/modal-context';
import { ThemeProvider } from './context/theme-context';
import './index.css'
// Add AOS imports
import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialize AOS
AOS.init({
    duration: 2000
});

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <ModalProvider>
                <App/>
            </ModalProvider>
        </ThemeProvider>
    </React.StrictMode>
);