import './experience.css'
import {BsCheckCircleFill} from 'react-icons/bs'
import { FiTarget, FiTrendingUp, FiUsers, FiCode, FiLayout, FiBriefcase } from 'react-icons/fi'
import { FaRocket, FaChartLine, FaClock, FaTrophy } from 'react-icons/fa'
import { motion } from 'framer-motion'

const Experience = () => {
  const reasons = [
    {
      id: 1,
      icon: <FiTarget />,
      title: "Strategic Thinking",
      description: "I approach each project with a strategic mindset, ensuring solutions align with business goals and user needs."
    },
    {
      id: 2,
      icon: <FiTrendingUp />,
      title: "Results-Driven",
      description: "My work consistently delivers measurable improvements in user engagement, conversion rates, and business metrics."
    },
    {
      id: 3,
      icon: <FiUsers />,
      title: "User-Centered",
      description: "I prioritize user needs and experiences, creating intuitive interfaces that delight and engage."
    },
    {
      id: 4,
      icon: <FiCode />,
      title: "Technical Expertise",
      description: "With deep knowledge in both design and development, I bridge the gap between aesthetics and functionality."
    },
    {
      id: 5,
      icon: <FiLayout />,
      title: "Modern Design",
      description: "I create contemporary, responsive designs that reflect current trends while maintaining timeless usability."
    },
    {
      id: 6,
      icon: <FiBriefcase />,
      title: "Professional Experience",
      description: "Over 8 years of experience working with major brands and delivering successful digital products."
    }
  ]

  const achievements = [
    {
      id: 1,
      icon: <FaRocket />,
      text: "Successfully delivered 50+ digital products for major brands",
      color: "#FF6B6B"
    },
    {
      id: 2,
      icon: <FaChartLine />,
      text: "Increased user engagement by average of 40% across projects",
      color: "#4ECDC4"
    },
    {
      id: 3,
      icon: <FaClock />,
      text: "Reduced development time by 30% through efficient workflows",
      color: "#45B7D1"
    },
    {
      id: 4,
      icon: <FaTrophy />,
      text: "Consistently exceeded client expectations and project goals",
      color: "#96C93D"
    }
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  }

  return (
    <section id="experience">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h2>What I Bring to Your Team</h2>
        <p className="section__subtitle">
          I bring a unique combination of design thinking, technical expertise, and business acumen to every project.
        </p>
      </motion.div>

      <div className="container experience__container">
        <motion.div 
          className="experience__cards"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {reasons.map(({ id, icon, title, description }) => (
            <motion.article 
              key={id} 
              className="experience__card"
              variants={cardVariants}
              whileHover={{ 
                scale: 1.03,
                boxShadow: "0 8px 30px rgba(0,0,0,0.12)"
              }}
            >
              <div className="experience__icon-wrapper">
                <div className="experience__icon">
                  {icon}
                </div>
              </div>
              <div className="experience__content">
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
            </motion.article>
          ))}
        </motion.div>

        <motion.div 
          className="experience__highlights"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          <h3>Key Achievements</h3>
          <div className="experience__list">
            {achievements.map(({ id, icon, text, color }) => (
              <motion.article 
                key={id} 
                className="experience__highlight"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: id * 0.1 + 0.6 }}
                whileHover={{ x: 10 }}
              >
                <div className="achievement__icon" style={{ backgroundColor: color }}>
                  {icon}
                </div>
                <p>{text}</p>
              </motion.article>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default Experience 