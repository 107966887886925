import './portfolio.css'
import IMG1 from '../../assets/project1.jpg'
import IMG2 from '../../assets/project2.jpg'
import IMG3 from '../../assets/project3.jpg'
import IMG4 from '../../assets/project4.jpg'
import IMG5 from '../../assets/project5.jpg'
import IMG6 from '../../assets/project6.jpg'
import IMG7 from '../../assets/project7.jpg'
import IMG8 from '../../assets/project8.jpg'
import IMG9 from '../../assets/project9.jpg'
import IMG10 from '../../assets/project10.jpg'
import IMG11 from '../../assets/project11.jpg'
import IMG12 from '../../assets/Project12.jpg'
import { useState } from 'react'

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = ['all', 'UI/UX', 'Frontend'];

  const projects = [
    {
      id: 1,
      category: 'UI/UX',
      image: IMG12,
      title: 'Evolve 4',
      role: 'UX/UI Designer & AI Integration Specialist',
      challenge: 'Modernise a legacy SaaS/ERP system used by major companies while maintaining functionality and improving user experience.',
      process: 'Conducted extensive user research with existing clients, analysed pain points, and created prototypes integrating AI for workflow optimisation.',
      solution: 'Redesigned the entire system with a modern interface, implemented AI-driven features for workflow optimisation, and enhanced decision-making capabilities.',
      impact: '• Improved user efficiency by 40%\n• Reduced training time by 50%\n• Increased client satisfaction by 35%',
      demo: 'https://evolve4.com/',
      studies: 'https://www.oddwalkstudios.com/evolve-4/'
    },
    {
      id: 2,
      category: 'UI/UX',
      image: IMG10,
      title: 'H+K Strategies',
      role: 'UX Designer & Data Visualisation Specialist',
      challenge: 'Create an AI-integrated dashboard system for an international advertising business to streamline marketing campaign creation.',
      process: 'Collaborated with data analysts to identify key metrics, designed data visualisation components, and integrated AI-powered insights.',
      solution: 'Developed an intuitive dashboard system that combines AI analytics with user-friendly campaign management tools.',
      impact: '• Reduced campaign setup time by 60%\n• Increased campaign effectiveness by 45%',
      demo: 'https://hillandknowlton.com/',
      studies: 'https://www.oddwalkstudios.com/hkstrategies/'
    },
    {
      id: 3,
      category: 'UI/UX',
      image: IMG7,
      title: 'Adviser Plus',
      role: 'UX Designer & Product Developer',
      challenge: 'Create a bolt-on solution for the Saas package Empower that would serve major UK businesses while maintaining high security and scalability.',
      process: 'Analysed client requirements, conducted stakeholder interviews, and developed prototypes with a focus on enterprise-level security and user experience.',
      solution: 'Developed a secure, scalable solution integrated with Empower, featuring role-based access control and customisable workflows.',
      impact: '• Successfully deployed to LSE, Barclays, BBC and others\n• Reduced implementation time by 40%',
      demo: 'https://adviserplus.com/our-solutions/empower/',
      studies: 'https://www.oddwalkstudios.com/adviser-plus-2/'
    },
    {
      id: 4,
      category: 'UI/UX',
      image: IMG1,
      title: 'Holmes Terry',
      role: 'UX/UI Designer',
      challenge: 'Revamp the website for a Manchester City Centre property management company to improve office and retail space lettings.',
      process: 'Conducted market research, created user personas, and developed a modern property showcase platform.',
      solution: 'Built a responsive website with virtual tours, detailed property information, and streamlined inquiry process.',
      impact: '• 45% increase in property inquiries\n• 30% faster letting process',
      demo: 'https://holmesterry.co.uk/',
      studies: 'https://www.oddwalkstudios.com/holmes-terry-casestudy/'
    },
    {
      id: 5,
      category: 'Frontend',
      image: IMG2,
      title: 'Odd Walk Studios',
      role: 'Frontend Developer & Designer',
      challenge: 'Create a comprehensive digital presence for a multi-faceted creative studio offering web design, 3D modeling, branding, and video services.',
      process: 'Developed a modern, responsive website showcasing multiple service offerings and portfolio pieces.',
      solution: 'Built a dynamic portfolio site with service categorisation and interactive elements.',
      impact: '• Increased client inquiries by 50%\n• Improved portfolio visibility',
      demo: 'https://www.oddwalkstudios.com/',
      studies: 'https://www.oddwalkstudios.com/'
    },
    {
      id: 6,
      category: 'Frontend',
      image: IMG3,
      title: 'Erdy',
      role: 'Frontend Developer & Art Curator',
      challenge: 'Consolidate and present a large collection of art pieces from an old dataset in a modern, accessible way.',
      process: 'Organised and digitised the art collection, created a database structure, and designed an intuitive interface.',
      solution: 'Created a digital art gallery with filtering capabilities and detailed artwork information.',
      impact: '• Successfully digitised over 1000 artworks\n• Enhanced artwork accessibility',
      demo: 'https://erdy.com/',
      studies: 'https://erdy.com/'
    },
    {
      id: 7,
      category: 'Frontend',
      image: IMG6,
      title: 'Card Guard Gallery',
      role: 'Frontend Developer & Database Designer',
      challenge: 'Create a filterable database system for an international poker card collection.',
      process: 'Developed advanced filtering and search capabilities for a large card database.',
      solution: 'Built a responsive gallery with detailed card information and trading features.',
      impact: '• Optimised for international visibility\n• Enhanced trading efficiency',
      demo: 'https://cardguardgallery.com/',
      studies: 'https://www.oddwalkstudios.com/card-guard-casestudy/'
    },
    {
      id: 8,
      category: 'Frontend',
      image: IMG11,
      title: 'PAM Health',
      role: 'Frontend Developer & E-commerce Specialist',
      challenge: 'Integrate multiple health-related stores under the PAM brand with various e-commerce platforms.',
      process: 'Designed banners/logos and implemented Shopify and Salesforce integrations.',
      solution: 'Created a unified e-commerce ecosystem for multiple health stores.',
      impact: '• Unified brand presence across stores\n• Increased cross-store sales',
      demo: 'https://66fit.co.uk/',
      studies: 'https://pamhealth.co.uk/'
    },
    {
      id: 9,
      category: 'Frontend',
      image: IMG9,
      title: 'Pinnacle',
      role: 'Frontend Developer & 3D Visualization Specialist',
      challenge: 'Modernise system interfaces and implement 3D visualization for UK-wide projects.',
      process: 'Created realistic 3D renders and enhanced compliance with government protocols.',
      solution: 'Developed a modern interface with integrated 3D visualisation capabilities.',
      impact: '• Improved project visualisation\n• Enhanced compliance tracking',
      demo: 'https://pinnacle.space/',
      studies: 'https://www.oddwalkstudios.com/pinnacle-casestudy/'
    }
  ];

  const filteredProjects = projects.filter(project => 
    activeCategory === 'all' ? true : project.category === activeCategory
  );

  return (
    <section id="portfolio">
      <h2>Recent Projects</h2>
      <p>Explore some of the recent projects I have worked on for my clients by using the toggle buttons to switch between different categories.</p>
      
      <div className="portfolio__categories">
        {categories.map(category => (
          <button
            key={category}
            className={`btn cat__btn ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      <div className="container portfolio__container">
        {filteredProjects.map(project => (
          <article key={project.id} className="project" data-aos="fade-up">
            <div className="project__image">
              <img src={project.image} alt={`${project.title} - Case Study`} />
            </div>
            <div className="project__content">
              <h3>{project.title}</h3>
              <p className="project__category">{project.category}</p>
              <p className="project__role">{project.role}</p>
              
              <div className="project__details">
                <div className="project__section">
                  <h4>Challenge</h4>
                  <p>{project.challenge}</p>
                </div>
                
                <div className="project__section">
                  <h4>Process</h4>
                  <p>{project.process}</p>
                </div>
                
                <div className="project__section">
                  <h4>Solution</h4>
                  <p>{project.solution}</p>
                </div>
                
                <div className="project__section">
                  <h4>Impact</h4>
                  <p>{project.impact}</p>
                </div>
              </div>
              
              <div className="project__cta">
                <a href={project.demo} className="btn modern-btn" target="_blank" rel="noreferrer">
                  <span>View Demo</span>
                  <div className="btn-glow"></div>
                </a>
                <a href={project.studies} className="btn modern-btn primary" target="_blank" rel="noreferrer">
                  <span>Case Study</span>
                  <div className="btn-glow"></div>
                </a>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;