import { THEME_ACTION_TYPES } from './theme-context'

const themeReducer = (state, action) => {
    switch (action.type) {
        case THEME_ACTION_TYPES.CHANGE_THEME:
            return {
                ...state,
                primary: action.payload
            }
        case THEME_ACTION_TYPES.CHANGE_BACKGROUND:
            return {
                ...state,
                background: action.payload
            }
        default:
            return state
    }
}

export default themeReducer