import {links, socials} from './data'
import './footer.css'
import { useState, useEffect } from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { BsPersonFill } from 'react-icons/bs'
import { MdWorkOutline } from 'react-icons/md'
import { RiServiceLine } from 'react-icons/ri'
import { BiMessageDetail } from 'react-icons/bi'
import Nav from '../floating-nav/Nav'
import data from '../floating-nav/data'

const Footer = () => {
  const [activeNav, setActiveNav] = useState('#');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.pageYOffset;

      sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 100;
        const sectionId = current.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveNav('#' + sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <footer>
        <div className="container footer__container">
          <ul className='nav__menu'>
            {
              links.map(fLink => <li key={fLink.id}><a href={fLink.link}>{fLink.title}</a></li>)
            }
          </ul>
          <div className="footer__socials">
            {
              socials.map(social => <a key={social.id} href={social.link} target="_blank" rel="noopener noreferrer">{social.icon}</a>)
            }
          </div>
        </div>
        <div className="footer__copyright">
          <small>2025 Richard Erdursun &copy; All Rights Reserved</small>
        </div>
      </footer>

      {/* Floating nav outside the footer element */}
      <div className='floating__nav'>
        {data.map(item => (
          <Nav 
            key={item.id} 
            item={item} 
            className={activeNav === item.link ? 'active' : ''}
          />
        ))}
      </div>
    </>
  )
}

export default Footer