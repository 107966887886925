import { useEffect } from 'react';

const GA_TRACKING_ID = 'G-XXXXXXXXXX'; // Replace with your Google Analytics ID
const HOTJAR_ID = 123456; // Replace with your Hotjar ID

const Analytics = () => {
  useEffect(() => {
    // Google Analytics
    const loadGA = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', GA_TRACKING_ID);
    };

    // Hotjar
    const loadHotjar = () => {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(script);
    };

    if (process.env.NODE_ENV === 'production') {
      loadGA();
      loadHotjar();
    }
  }, []);

  return null;
};

export default Analytics; 